/**
 * @author Akshay Kumar Singh
 * @file Login Component
 * @flow
 */

import React, { Component } from 'react';
import { spinnerService } from '../../shared/services/spinner.service';
import ROUTES from '../../shared/constants/routes';
import { CallApi } from '../../shared/services/api.service';
import CONFIG from '../../configuration';
import * as CONSTANT from '../../shared/constants/data';
import { setData, removeData } from '../../shared/utils/storage.util';
import Validator from '../../shared/validator';
import AlertModal from '../../shared/modals/AlertModal';
import { getRoleBasedRoute } from '../../shared/utils/helper.util';

class LoginComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
    }

    componentDidMount() {
        removeData('role');
    }

    /**
     * @description This function sets value according to their variable name.
     */
    handleChange = (event) => {
        event.preventDefault();
        let { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    /**
     * @description This function sends random generated password to the requested email.
     */
    requestPassword = () => {
        if (!!this.state.email) {
            const reqPassObj = {
                email: this.state.email
            };
            spinnerService.show('Oval', 'Reset Password');
            CallApi(
                'POST',
                CONFIG.forgotPassword,
                {},
                reqPassObj,
                '',
                'getRequestPassword',
                this,
                'en'
            );
        } else {
            AlertModal.showAlert('Please enter email id');
        }
    };

    /**
     * @description forgotPassword API callback function
     * @param response This is the response structure received from API
     */
    getRequestPassword = (response) => {
        if (response.success) {
            AlertModal.showAlert(response.message);
        }
    };

    /**
     * @description This function is called when login is pressed
     */

    handleSubmit = (event) => {
        event.preventDefault();
        if (!Validator.allValid()) {
            Validator.showMessages();
            this.forceUpdate();
        } else {
            const loginObj = {
                email: this.state.email,
                password: this.state.password
            };
            spinnerService.show('Oval', 'Login User');
            CallApi('POST', CONFIG.login, {}, loginObj, '', 'authorizeLogin', this, 'en');
        }
    };

    /**
     * @description Login API callback function
     * @param response This is the response structure received from API
     */

    authorizeLogin = (response) => {
        if (response.success) {
            const { role_name } = response.response.result;
            const loginData = {
                userData: response.response.result
            };
            setData('auth', response.response.result.token);
            setData('isLogin', true);
            setData('loginData', loginData);
            setData('token', response.response.result.token);
            setData(role_name, true);
            if (loginData.userData.is_active == 0) {
                this.props.history.push(ROUTES.CHANGEPASSWORD);
            } else {
                let route = getRoleBasedRoute();
                this.props.history.push(route);
            }
        }
    };

    render() {
        return (
            <div className="login-main">
                <a href="" onClick={(e) => e.preventDefault()} className="login-logo testCICDAGAIN">
                    <img
                        src={require('../../assets/images/niagara-logo-color-2-x.png')}
                        alt="Login Logo"
                    />
                </a>
                <div className="login-form-container">
                    <form autoComplete="off" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label className="login-icon email-icon">{CONSTANT.LABLES.Email}</label>
                            <input
                                className={
                                    'form-control ' +
                                    (Validator.message('email', this.state.email, 'email')
                                        ? 'error'
                                        : '')
                                }
                                type="text"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                onKeyPress={() => Validator.showMessageFor('email')}
                                placeholder="Email"
                                autoComplete="off"
                                autoFocus={true}
                                vNum="1.01"
                            />
                            {Validator.message('email', this.state.email, 'email')}
                        </div>
                        <div className="form-group">
                            <label className="login-icon password-icon">
                                {CONSTANT.LABLES.Password}
                            </label>
                            <input
                                className={
                                    'form-control ' +
                                    (Validator.message('password', this.state.password, 'password')
                                        ? 'error'
                                        : '')
                                }
                                type="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                onKeyPress={() => Validator.showMessageFor('password')}
                                placeholder="Password"
                            />
                            {Validator.message('password', this.state.password, 'password')}
                        </div>
                        <a
                            href="!#"
                            className="forgot-password"
                            onClick={(e) => {
                                this.handleChange(e);
                                this.requestPassword();
                                Validator.showMessageFor('email');
                            }}>
                            {CONSTANT.LABLES.ForgotPassword}
                        </a>
                        <div className="login-btn">
                            <button className="btn-prmry" name="Login" value="Login">
                                {CONSTANT.BUTTONS.Login}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
export default LoginComponent;
