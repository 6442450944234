import BASE_URL from './env';

const CONFIG = {
    login: `${BASE_URL}/login`,
    plantList: `${BASE_URL}/plantList`,
    userAuditList: `${BASE_URL}/userAuditListing`,
    taskList: `${BASE_URL}/taskList`,
    userTaskList: `${BASE_URL}/userTaskList`,
    userListing: `${BASE_URL}/userListing`,
    workOrderList: `${BASE_URL}/workOrderList`,
    getQuesList: `${BASE_URL}/getQuesList`,
    addMHTaskQuesManual: `${BASE_URL}/addMHTaskQuesManual`,
    subTaskQues: `${BASE_URL}/subTaskQues`,
    getWOSearchQues: `${BASE_URL}/workOrderSearch`,
    getWOQues: `${BASE_URL}/getWorkOrderReference`,
    getWoOptions: `${BASE_URL}/getWoOptions`,
    getPRHeading: `${BASE_URL}/inventoryHeading`,
    searchPRData: `${BASE_URL}/getInventoryCode`,
    addTaskQues: `${BASE_URL}/addTaskQues`,
    addWOQues: `${BASE_URL}/addWorkOrderReference`,
    addPInventryQues: `${BASE_URL}/addInventoryOracleAns`,
    uploadQuesImage: `${BASE_URL}/uploadWoQuesImages`,
    updateAudit: `${BASE_URL}/updateAudit`,
    updateTask: `${BASE_URL}/updateTask`,
    createTask: `${BASE_URL}/createTask`,
    createAudit: `${BASE_URL}/createAudit`,
    deleteAudit: `${BASE_URL}/deleteAudit`,
    useridTaskList: `${BASE_URL}/useridTaskList`,
    previewList: `${BASE_URL}/previewList`,
    updateTaskComment: `${BASE_URL}/updateTaskComment`,
    getLineList: `${BASE_URL}/getLineList`,
    getAssetList: `${BASE_URL}/getAssetList`,
    getIp: `https://jsonip.com/`,
    changePassword: `${BASE_URL}/changePassword`,
    forgotPassword: `${BASE_URL}/forgot_Password`,
    deleteTask: `${BASE_URL}/deleteTask`,
    deleteTaskImages: `${BASE_URL}/deleteTaskImages`,
    fetchDateWorkOrder: `${BASE_URL}/fetchDateWorkOrder`,
    uploadCSV: `${BASE_URL}/uploadCSV`,
    createInventoryCSV: `${BASE_URL}/createInventoryCSV`,
    verifyGenerateReport: `${BASE_URL}/validateGenerateReport`,
    downloadTask: `${BASE_URL}/downloadTask`,
    getOhqList: `${BASE_URL}/getOhqList`,
    assignBack: `${BASE_URL}/assignBack`,
    downloadWOExcel: `${BASE_URL}/generateWoReferenceExcel?id={task_id}&fileName={fileName}`,
    downloadPRExcel: `${BASE_URL}/generatePartsRoomExcel?id={task_id}&fileName={fileName}`,
    insertTabs: `${BASE_URL}/insertTabs`,
    deleteTabTask: `${BASE_URL}/deleteTabTask`,
    generateReport: `${BASE_URL}/generateReport`,
    generateIntermediateReport: `${BASE_URL}/generateIntermediateReport`,
    getFaq: `${BASE_URL}/getFaq`,
    addCostAnswers: `${BASE_URL}/addCostAnswers`,
    getCostAnswers: `${BASE_URL}/getCostAnswers`,
    addWBQues: `${BASE_URL}/addWBQues`,
    getWBQues: `${BASE_URL}/getWBQues`,
    wbQuesIsApproved: `${BASE_URL}/wbQuesIsApproved`,
    getAuditImages: `${BASE_URL}/getAuditImages`,
    auditCategories: ['ARMA'],
    ARMACat: 'ARMA',
    DVCat: 'Diagnostic Visit'
};

export default CONFIG;
